import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ReleaseContext } from "../context/ReleaseContext.js";

export default function Series() {
  const release = useContext(ReleaseContext);

  return (
    <section className="series">
      <h2 className="section-title">תכניות בערוץ HOT 8</h2>
      <div className="inside">
        {release?.releaseData?.shows.map((show, key) => (
          <div className="serie" key={key}>
            <Link to={`/show/${show._id}`}>
              <div className="img-wrap">
                <img
                  src={show?.releaseData?.mainImage[0].src}
                  alt={show.allText}
                />
              </div>
              <div className="data">
                <h3>{show?.releaseData?.title}</h3>
                <h4>{show?.releaseData?.subtitle}</h4>
              </div>
            </Link>
          </div>
        ))}

        {/* <div className="serie">
          <a>
            <img src="./images/thumb-2.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-3.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-4.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-5.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-6.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-7.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-8.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-9.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-1.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-2.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-3.jpg" alt="" />
          </a>
        </div>
        <div className="serie">
          <a>
            <img src="./images/thumb-4.jpg" alt="" />
          </a>
        </div> */}
      </div>
    </section>
  );
}
