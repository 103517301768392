import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { searchFor } from "../services/release.service";

export default function Search() {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState();
  const [loading, setLoading] = useState();

  useMemo(async () => {
    console.log("searching for...", searchTerm);
    setLoading(true);
    const results = await searchFor(searchTerm);
    setResults(results);
    console.log("results", results);
    setLoading(false);
    return results;
  }, [searchTerm]);

  return (
    <section className="series">
      <div className="search-wrap">
        <div className="wrap">
          <input
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="הקלידו כאן..."
          />
        </div>
      </div>
      {searchTerm &&
        (loading ? (
          <div className="wrap"><h5>מחפש...</h5></div>
        ) : !results.length ? (
          <div className="wrap"><h5>לא נמצא</h5></div>
        ) : (
          <div className="inside">
            {results?.map((show, key) => (
              <div className="serie" key={key}>
                <Link to={`/show/${show._id}`}>
                  <div className="img-wrap">
                    <img
                      src={show?.releaseData?.mainImage[0].src}
                      alt={show.allText}
                    />
                  </div>
                  <div className="data">
                    <h3>{show?.releaseData?.title}</h3>
                    <h4>{show?.releaseData?.subtitle}</h4>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ))}
    </section>
  );
}
