import React, { useEffect, useState } from "react";
import { getAllReleases } from "../services/release.service";

export default function Archive() {
  const [releases, setReleases] = useState();

  useEffect(() => {
    console.log("fetching release!");
    getAllReleases().then((data) => {
      console.log("releases data", data);
      setReleases(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wrap">
      {!releases ? (
        <h3>טוען...</h3>
      ) : (
        releases.map((release, key) => {
          return (
            <div key={key}>
              <a title={release?.releaseData?.subtitle}>
                {release?.releaseData?.title}
              </a>
            </div>
          );
        })
      )}
    </div>
  );
}
