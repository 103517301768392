import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getShow } from "../services/release.service";

export default function Show(props) {
  const { showId, page } = props.match.params;
  console.log("props.match.params", props.match.params);

  const [show, setShow] = useState();

  useEffect(() => {
    getShow(showId).then((data) => {
      console.log("show", data);
      setShow(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wrap">
      <section className="show">
        {!show ? (
          <h5>טוען...</h5>
        ) : !show._id ? (
          <h5>תכנית לא נמצאה</h5>
        ) : (
          <div className="inside">
            <nav>
              <ul>
                <li className={!page ? "active" : ""}>
                  <Link to={`/show/${showId}/`}>תקציר</Link>
                </li>
                <li className={page === "play" ? "active" : ""}>
                  <Link to={`/show/${showId}/play`}>לצפייה</Link>
                </li>
                <li className={page === "pictures" ? "active" : ""}>
                  <Link to={`/show/${showId}/pictures`}>תמונות</Link>
                </li>
                <li className={page === "festivals" ? "active" : ""}>
                  <Link to={`/show/${showId}/festivals`}>פסטיבלים</Link>
                </li>
                <li className={page === "time" ? "active" : ""}>
                  <Link to={`/show/${showId}/time`}>שעות שידור</Link>
                </li>
                <li className={page === "full" ? "active" : ""}>
                  <Link to={`/show/${showId}/full`}>פרקים מלאים</Link>
                </li>
              </ul>
            </nav>
            <hr className="vertical" />
            <div className="content">
              {!page ? (
                <div className="summary">
                  <img
                    src={show?.releaseData?.mainImage[0].src}
                    className="main-thumbnail"
                    alt={show?.releaseData?.mainImage[0].title}
                  />
                  <h2>{show?.releaseData?.title}</h2>
                  <h3 className="subtitle">
                    {show?.releaseData?.subtitle}
                  </h3>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: show?.preview?.releaseData?.content,
                    }}
                  ></div>
                  <hr />
                  <p>
                    [כאן אמור להופיע היכן ישודר ומתי. מידע אמור להישלח מה־API]
                  </p>
                  <hr />
                </div>
              ) : page === "play" ? (
                <div className="play">
                  {!show?.releaseData ||
                  !show?.releaseData?.videos ||
                  !show?.releaseData?.videos.length ? (
                    <h5>אין סרטונים</h5>
                  ) : (
                    show?.releaseData?.videos.map((video, key) => (
                      <div key={key}>
                        <h3>{video.title}</h3>
                        <div className="video-wrapper">
                          <iframe
                            href={`${video.link.replace(
                              "https://youtu.be/",
                              "https://youtube.com/embed/"
                            )}`}
                            frameBorder="0"
                            title={video.title}
                          ></iframe>
                        </div>
                        <a href={video.link} target="_blank" rel="noreferrer">
                          {video.link}
                        </a>
                      </div>
                    ))
                  )}
                </div>
              ) : page === "pictures" ? (
                <div className="pictures">
                  {!show?.releaseData ||
                  !show?.releaseData?.imageGallery ||
                  !show?.releaseData?.imageGallery.length ? (
                    <h5>אין תמונות</h5>
                  ) : (
                    show?.releaseData?.imageGallery.map((img, key) => (
                      <div className="picture" key={key}>
                        <div className="inside">
                          <img src={img.src} alt={img.title} />
                          <div className="links">
                            <a href={img.src} target="_blank" rel="noreferrer">
                              פתח
                            </a>
                            <a href={img.src} download={img.title}>
                              הורד
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              ) : page === "festivals" ? (
                <div className="festivals">המידע של הפסטיבלים חסר ב־API</div>
              ) : page === "time" ? (
                <div className="time">
                  {!show?.preview?.releaseData?.broadcastTimes ? (
                    <h4>אין שעות שידור</h4>
                  ) : (
                    <table className="pretty-table">
                      <thead>
                        <tr>
                          <td>תוכנית</td>
                          <td>ימים</td>
                          <td>תאריך</td>
                          <td>שעות</td>
                        </tr>
                      </thead>
                      <tbody>
                        {show?.preview?.releaseData?.broadcastTimes.map(
                          (broadcastTime, key) => (
                            <tr key={key}>
                              <td>{show?.preview?.releaseData?.title}</td>
                              <td>{broadcastTime.dayText}</td>
                              <td>{broadcastTime.dateText}</td>
                              <td>{broadcastTime.hourText}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              ) : page === "full" ? (
                <div className="full">המידע של הפרקים המלאים חסר ב־API</div>
              ) : (
                <h2>עמוד לא נמצא</h2>
              )}
              {/* <pre dir="ltr">{JSON.stringify(show, null, 4)}</pre> */}
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
