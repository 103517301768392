import React, { useContext } from 'react'
import { ReleaseContext } from '../context/ReleaseContext';

export default function Airing() {
    // eslint-disable-next-line no-unused-vars
    const release = useContext(ReleaseContext);
    return (
        <div>
            כאן יופיע לוח השידורים. המידע חסר כרגע ב־API
        </div>
    )
}
