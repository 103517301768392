import React, { useContext } from 'react'
import { ReleaseContext } from '../context/ReleaseContext';

export default function Contact() {
    // eslint-disable-next-line no-unused-vars
    const release = useContext(ReleaseContext);
    return (
        <div>
            כאן יופיע פרטי צור הקשר. המידע חסר כרגע ב־API
        </div>
    )
}
