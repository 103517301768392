import { NavLink } from "react-router-dom";

export default function header() {
  return (
    <header id="header" className="header">
      <NavLink to="/">
        <section className="logo">
          <h1>
            <img src="/favicon.png" alt="Hot" />
          </h1>
        </section>
      </NavLink>
      <nav>
        <ul>
          <li>
            <NavLink to="/premieres" activeClassName="is-active">
              בכורות אוגוסט
            </NavLink>
          </li>
          <li>
            <NavLink to="/summary" activeClassName="is-active">
              תקצירי החודש
            </NavLink>
          </li>
          <li>
            <NavLink to="/airing" activeClassName="is-active">
              לוח שידורים
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" activeClassName="is-active">
              צור קשר
            </NavLink>
          </li>
          <li>
            <NavLink to="/archive" activeClassName="is-active">
              ארכיון
            </NavLink>
          </li>
        </ul>
      </nav>
      <NavLink to="/search" activeClassName="is-active"><div className="search-button"></div></NavLink>
    </header>
  );
}
